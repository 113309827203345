<template>
  <section>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Szukaj"
      single-line
      hide-details
      class="mb-3 elevation-1 pa-3"
      clearable
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :header-props="headerProps"
      :items="orders"
      :search="search"
      :footer-props="{
        'items-per-page-text': 'Zamówień na stronę',
        'items-per-page-options': [10, 20, 50, 100],
      }"
      :items-per-page="10"
      class="elevation-2"
      :loading="orderTableLoading"
      loading-text="Wczytywanie... Proszę czekać"
      :sort-by="['datetime_created']"
      :sort-desc="true"
      multi-sort
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.order_no }}</td>
          <td>{{ item.client_name }}</td>
          <td>{{ item.datetime_created }}</td>
          <td :class="`${getColor(item)}--text`">{{ item.status_name }}</td>
          <td width="10px">
            <v-menu bottom origin="center center" transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small v-bind="attrs" v-on="on">
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title
                    ><v-btn
                      block
                      color="info"
                      @click.prevent="showOrderView(item)"
                      >Podgląd</v-btn
                    ></v-list-item-title
                  >
                </v-list-item>
                <v-divider class="my-1"></v-divider>
                <v-list-item>
                  <v-list-item-title
                    ><v-btn
                      block
                      color="green"
                      dark
                      @click.prevent="changeStatus(item, 'new')"
                      >Status: Nowe</v-btn
                    ></v-list-item-title
                  >
                </v-list-item>
                <v-list-item>
                  <v-list-item-title
                    ><v-btn
                      block
                      color="warning"
                      dark
                      @click.prevent="changeStatus(item, 'in_progress')"
                      >Status: W trakcie</v-btn
                    ></v-list-item-title
                  >
                </v-list-item>
                <v-list-item>
                  <v-list-item-title
                    ><v-btn
                      block
                      color="red"
                      dark
                      @click.prevent="changeStatus(item, 'completed')"
                      >Status: Zakończ</v-btn
                    ></v-list-item-title
                  >
                </v-list-item>

                <v-divider class="my-3"></v-divider>
                <v-list-item>
                  <v-list-item-title
                    ><v-btn
                      block
                      color="red"
                      dark
                      @click.prevent="changeStatus(item, 'canceled')"
                      >Status: Anuluj</v-btn
                    ></v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>

      <template v-slot:footer.page-text="items">
        {{ items.pageStart }}-{{ items.pageStop }} z
        {{ items.itemsLength }}
      </template>
    </v-data-table>
  </section>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Nr zamówienia", value: "order_no" },
        { text: "Klient", value: "client_name" },
        { text: "Data", value: "datetime_created" },
        { text: "Status", value: "status" },
        { text: "Akcje", sortable: false },
      ],
      headerProps: {
        sortByText: "Sortowanie",
      },
    };
  },
  props: {
    orders: {
      type: Array,
      required: true,
    },
    orderTableLoading: {
      type: Boolean,
      required: true,
    },
    apiLink: {
      type: String,
      required: true,
    },
  },
  methods: {
    showOrderView(order) {
      this.$emit("showOrderView", order);
    },
    getColor(order) {
      if (order.status == "new") {
        return "success";
      } else if (order.status == "in_progress") {
        return "warning";
      } else if (order.status == "canceled") {
        return "red";
      } else {
        return "info";
      }
    },
    changeStatus(order, status) {
      this.$emit("changeStatus", order, status);
    },
  },
};
</script>