<template>
  <v-row justify="center" class="order-view">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keydown.esc="closeOrderView"
    >
      <v-card>
        <v-toolbar dark color="secondary">
          <v-btn icon dark @click="closeOrderView">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >Zamówienie nr {{ orderToShow.order_no }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <a
              v-if="orderToShow.order_no"
              :href="`${apiLink}/api/uploads/orders/${orderToShow.order_no.replace(
                '/',
                '_'
              )}_order.csv`"
              download
            >
              <v-btn dark color="secondary" style="height: 100%">
                Pobierz CSV
              </v-btn>
            </a>
            <v-menu bottom origin="center center" transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="`${getColor(orderToShow)}--text`"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  Status: {{ orderToShow.status_name }}
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title
                    ><v-btn
                      block
                      color="green"
                      dark
                      @click.prevent="changeStatus(orderToShow, 'new')"
                      >Zmień na: Nowe</v-btn
                    ></v-list-item-title
                  >
                </v-list-item>
                <v-list-item>
                  <v-list-item-title
                    ><v-btn
                      block
                      color="warning"
                      dark
                      @click.prevent="changeStatus(orderToShow, 'in_progress')"
                      >Zmień na: W trakcie</v-btn
                    ></v-list-item-title
                  >
                </v-list-item>
                <v-list-item>
                  <v-list-item-title
                    ><v-btn
                      block
                      color="red"
                      dark
                      @click.prevent="changeStatus(orderToShow, 'completed')"
                      >Zmień na: Zakończone</v-btn
                    ></v-list-item-title
                  >
                </v-list-item>

                <v-divider class="my-3"></v-divider>
                <v-list-item>
                  <v-list-item-title
                    ><v-btn
                      block
                      color="red"
                      dark
                      @click.prevent="changeStatus(orderToShow, 'canceled')"
                      >Zmień na: Anulowane</v-btn
                    ></v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar-items>
        </v-toolbar>

        <v-container fluid>
          <v-row>
            <v-col cols="12" class="mt-3"
              ><v-card elevation="2"
                ><v-card-text class="text-h6 text-left text--primary"
                  ><h1>
                    Zamówienie od {{ orderToShow.client_name }}
                  </h1></v-card-text
                ></v-card
              ></v-col
            >
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-simple-table dense class="elevation-2">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Nazwa</th>
                      <th class="text-left">Ilość jednostkowa</th>
                      <th class="text-left">Ean</th>
                      <th class="text-left">Cena</th>
                      <th class="text-left">W opakowaniu</th>
                      <th class="text-left">Waga</th>
                      <th class="text-left">Producent</th>
                      <th class="text-left">Kategoria</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="product in orderToShow.products"
                      :key="product.product.id"
                    >
                      <td>{{ product.product.name }}</td>
                      <td>{{ product.count }}</td>
                      <td>{{ product.product.ean }}</td>
                      <td>{{ `${product.product.price} zł` }}</td>
                      <td>
                        {{
                          `${product.product.in_package} (${product.product.unit})`
                        }}
                      </td>
                      <td>{{ product.product.weight }}</td>
                      <td>{{ product.product.producer_name }}</td>
                      <td>{{ product.product.category_name }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                outlined
                label="Komentarz do zamówienia"
                v-model="orderToShow.comments"
                readonly
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card class="mx-auto" outlined>
                <v-list-item>
                  <v-container>
                    <v-row justify="center" align="center">
                      <v-col
                        ><v-list-item-content>
                          <v-list-item-title class="mb-1 text-left">
                            <span class="text-h4 text-left">Podsumowanie</span>
                          </v-list-item-title>
                        </v-list-item-content></v-col
                      >
                      <v-col cols="1">
                        <p>Waga</p>
                        <v-text-field
                          :value="`${weightTotal} kg`"
                          class="product-summary"
                          type="text"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <p>Ilość</p>
                        <v-text-field
                          :value="`${countTotal}`"
                          class="product-summary"
                          type="text"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <p>Kwota</p>
                        <v-text-field
                          :value="`${sumTotal} zł`"
                          class="product-summary"
                          type="text"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "OrderView",
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    orderView(newVal) {
      this.dialog = newVal;
    },
  },
  props: {
    orderToShow: {
      type: Object,
      required: true,
    },
    apiLink: {
      type: String,
      required: true,
    },
    orderView: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    sumTotal() {
      var sumTotal = 0;
      this.orderToShow.products.forEach((p) => {
        sumTotal += p.product.price * p.count;
      });
      return sumTotal.toFixed(2);
    },
    weightTotal() {
      var weightTotal = 0;
      this.orderToShow.products.forEach((p) => {
        weightTotal += p.product.weight * p.count;
      });
      return weightTotal.toFixed(2);
    },
    countTotal() {
      var countTotal = 0;
      this.orderToShow.products.forEach((p) => {
        countTotal += p.count;
      });
      return countTotal;
    },
  },
  methods: {
    closeOrderView() {
      this.$emit("closeOrderView");
    },
    getColor(order) {
      if (order.status == "new") {
        return "success";
      } else if (order.status == "in_progress") {
        return "warning";
      } else if (order.status == "canceled") {
        return "red";
      } else {
        return "info";
      }
    },
    changeStatus(order, status) {
      this.$emit("changeStatus", order, status);
      this.closeOrderView();
    },
    ediGenerate(orderToShow) {
      console.log(orderToShow);
    },
  },
};
</script>

<style>
.order-view .product-summary {
  width: 100px;
}
</style>