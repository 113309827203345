<template>
  <section class="cms-customer">
    <CMSNavTop :apiLink="apiLink" />
    <CMSNavSidebar />
    <div style="padding-left: 56px">
      <Alert
        :alertShow="alertShow"
        :alertText="alertText"
        :alertType="alertType"
        @closeAlert="closeAlert"
      />
      <v-container fluid>
        <v-row>
          <v-col class="mt-3"
            ><v-card elevation="2"
              ><v-card-text class="text-h4 text-center text--primary"
                ><h1>Lista zamówień</h1></v-card-text
              ></v-card
            ></v-col
          >
        </v-row>
        <v-row>
          <v-col>
            <OrdersTable
              :orderTableLoading="orderTableLoading"
              :orders="orders"
              :apiLink="apiLink"
              @showOrderView="showOrderView"
              @changeStatus="changeStatus"
            />
          </v-col>
        </v-row>
        <OrderView
          :orderView="orderView"
          :apiLink="apiLink"
          :orderToShow="orderToShow"
          @closeOrderView="closeOrderView"
          @changeStatus="changeStatus"
        />
      </v-container>
    </div>
  </section>
</template>

<script>
import CMSNavTop from "@/components/CMS/Navigations/CMSNavTop.vue";
import CMSNavSidebar from "@/components/CMS/Navigations/CMSNavSidebar.vue";
import Alert from "@/components/Alert.vue";
import OrdersTable from "@/components/CMS/Orders/OrdersTable.vue";
import OrderView from "@/components/CMS/Orders/OrderView.vue";

export default {
  name: "CMSOrders",
  components: {
    CMSNavTop,
    CMSNavSidebar,
    OrdersTable,
    OrderView,
    Alert,
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      orderView: false,
      orderTableLoading: false,
      orders: [],
      alertText: "",
      alertType: "success",
      alertShow: false,
      orderToShow: { products: [] },
    };
  },
  created() {
    this.getOrders();
  },
  methods: {
    showAlert(alertText, alertType) {
      this.alertShow = true;
      this.alertText = alertText;
      this.alertType = alertType;
      console.log(alertText);
    },
    closeAlert() {
      this.alertShow = false;
    },
    showOrderView(order) {
      this.orderToShow = order;
      this.orderView = true;
    },
    closeOrderView() {
      this.orderView = false;
    },
    async getOrders() {
      this.orderTableLoading = true;
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/orders/getAllOrders.php`,
        data: {
          token: localStorage.token,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.orders = res.data;
          } else {
            this.showAlert(
              `Błąd podczas pobierania produktów. Kod błędu API: ${res.status}`,
              "error"
            );
          }
        })
        .catch((err) => {
          this.showAlert(`Błąd pobierania produktów. Błąd: ${err}`, "error");
        });
      this.orderTableLoading = false;
    },
    async changeStatus(order, status) {
      this.orderTableLoading = true;
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/orders/changeOrderStatus.php`,
        data: {
          token: localStorage.token,
          order: order,
          status: status,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.getOrders();
          } else {
            this.showAlert(
              `Błąd podczas pobierania produktów. Kod błędu API: ${res.status}`,
              "error"
            );
          }
        })
        .catch((err) => {
          this.showAlert(
            "showAlert",
            `Błąd pobierania produktów. Błąd: ${err}`,
            "error"
          );
        });
      this.orderTableLoading = false;
    },
  },
};
</script>